import { TextInput, DateInput } from '@zipou/front_tools'
import { ItemAutoCompleteInput } from '../../Item/Input/ItemAutoCompleteInput'
import { OrderLineInput } from 'model'


type OrderLineFormProps = {
  orderLine: OrderLineInput,
  errors: any,
  onChange: (d: OrderLineInput) => void
}

export const OrderLineForm = (props: OrderLineFormProps) => {

  const errors = props?.errors
  const { quantity, dateStart, dateEnd, itemId, description } = props?.orderLine
  // const itemListFiltered = itemList && [...itemList].sort((el1: any, el2: any) => el1.label.localeCompare(el2.label))
  return (
    <div>
      <ItemAutoCompleteInput isError={errors?.itemId} value={itemId || ""} onChange={(value) => {
        props?.onChange({
          ...props?.orderLine,
          itemId: value,
        })
      }} />
      <div className="input-group">
        <div className="input-group-text">Quantité</div>
        <input type="number" step={1} value={quantity || ""} name="quantity" placeholder="Quantité" className="form-control" onChange={(e: any) => {
          props?.onChange({
            ...props?.orderLine,
            quantity: Number(e.target.value),
          })
        }} />
      </div>
      <TextInput label="Description" value={description || ""} id="description" errors={errors} onChange={v => {
        props?.onChange({
          ...props?.orderLine,
          description: v,
        })
      }} />
      <div className="input-group">
        <span className="input-group-text">Date</span>
        <div className="form-control" style={{ height: "100%" }}>
          <DateInput id="dateStart" errors={errors} label="Date Début" value={Number(dateStart) * 1000 || 0} onChange={(v) => props?.onChange({
            ...props?.orderLine,
            dateStart: Math.round(v / 1000),
          })} />
          <DateInput id="dateEnd" errors={errors} label="Date Fin" value={Number(dateEnd) * 1000 || 0} onChange={(v) => props?.onChange({
            ...props?.orderLine,
            dateEnd: Math.round(v / 1000),
          })} />
        </div>
      </div>

    </div>
  )
}
