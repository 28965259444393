import { useState } from "react"
import { ModalRouterComponent } from "../context"
import { Modal } from "@zipou/front_tools"

type ModalRouterProps = {
  children: any,
  modalRouterExternalContext: React.Context<{ componentStack: any[], pushComponent: (component: ModalRouterComponent) => void, closeModal: () => void, }>,
}

export const ModalRouter = ({ children, modalRouterExternalContext }: ModalRouterProps) => {

  const [componentStack, updateComponentStack] = useState<ModalRouterComponent[]>([])

  const closeModal = () => {
    updateComponentStack(componentStack => componentStack.slice(0, -1))
  }

  const pushComponent = (component: any) => {
    updateComponentStack(componentStack => [
      ...(componentStack || []),
      component,
    ])
  }

  const hasComponentToDisplay = componentStack?.length > 0
  const componentToDisplay = componentStack[componentStack?.length - 1] || {}

  return <>
    <modalRouterExternalContext.Provider value={{ componentStack, pushComponent, closeModal }} >
      {hasComponentToDisplay && <Modal size={componentToDisplay?.modalSize || "lg"} display={hasComponentToDisplay} title={componentToDisplay.title || ""} onClose={closeModal}>
        {componentToDisplay.component}
      </Modal>}
      <>
        {children}
      </>
    </modalRouterExternalContext.Provider>
  </>
}