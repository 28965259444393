import { useQuery } from "@apollo/client"
import { IconEdit, IconSearch, IconTrash, Modal, Pagination } from "@zipou/front_tools"
import customerGetGql from "graphql/BILLING/Customer/query/customerGet.gql"
import customerViewGql from "graphql/BILLING/Customer/query/customerView.gql"
import { Contact, Cursor, CursorInput, Customer, Invoice, InvoiceListInput, InvoiceStatusEnum, Order, OrderStatusEnum } from "model"
import { InvoiceStatus } from "../../Invoice/Status/InvoiceStatus"
import { CustomerEdit } from "../Edit/CustomerEdit"
import { useContext, useState } from "react"
import { URL_INVOICE_PDF } from "static/constant/backoffice/url"
import { findToken } from "helpers/User"
import { Vars } from "libs/Vars"
import { InvoiceEdit } from "../../Invoice/Edit/InvoiceEdit"
import { ContactEdit } from "../../Contact/Edit/ContactEdit"
import { OrderEdit } from "../../Order/Edit/OrderEdit"
import { OrderStatus } from "../../Order/Status/OrderStatus"
import invoiceListGql from "graphql/BILLING/Invoice/query/invoiceList.gql"
import { PaymentPrefList } from "../../PaymentPref/List/PaymentPrefList"
import { userContext } from "context/User"
import { InvoiceDisplay } from "../../Invoice/Display/InvoiceDisplay"

type CustomerViewProps = {
  customerId: string,
}

export const CustomerView = ({ customerId }: CustomerViewProps) => {

  const [focusEdit, updateFocusEdit] = useState(false)
  const [focusInvoiceView, updateFocusInvoiceView] = useState<Invoice | null>()
  const [focusInvoiceEdit, updateFocusInvoiceEdit] = useState<Invoice | null>()
  const [focusContactEdit, updateFocusContactEdit] = useState<Contact | null>()
  const [focusOrderEdit, updateFocusOrderEdit] = useState<Order | null>()

  const { data } = useQuery<{ customer: Customer }>(customerViewGql, {
    variables: {
      id: customerId
    }
  })

  const contractId = data?.customer?.Contract?.id

  const invoiceQuery = useQuery<{ list: { nodes: Invoice[], cursor: Cursor } }, { cursor: CursorInput, input: InvoiceListInput }>(invoiceListGql, {
    variables: {
      cursor: {
        page: 1,
        limit: 20,
      },
      input: {
        contract: contractId ? [contractId] : [],
        status: [
          InvoiceStatusEnum.STATUS_DRAFT,
          InvoiceStatusEnum.STATUS_ERROR,
          InvoiceStatusEnum.STATUS_CANCELLED,
          InvoiceStatusEnum.STATUS_PAID,
          InvoiceStatusEnum.STATUS_PAYMENT_WAITING,
          InvoiceStatusEnum.STATUS_PENDING,
        ],
        // orderField?: InputMaybe<InvoiceOrderEnum>
        // orderDirection?: InputMaybe<InvoiceOrderDirectionEnum>
      }
    },
    skip: !contractId,
  })

  const { api_enpoint } = Vars

  const customer = data?.customer
  const invoiceList = invoiceQuery?.data?.list?.nodes || []
  const orderList = customer?.Order
  const contactList = customer?.Contact

  const user = useContext(userContext)

  const stripePk = user?.Company?.stripePk


  return <div>
    {focusEdit && <Modal display={focusEdit} title={`${customer?.name}`} onClose={() => updateFocusEdit(false)} >
      <CustomerEdit id={customerId} onDone={() => updateFocusEdit(false)} />
    </Modal>}
    {focusInvoiceView && <Modal display={!!focusInvoiceView} title="Aperçu" onClose={() => updateFocusInvoiceView(null)}>
      <InvoiceDisplay invoice={focusInvoiceView} />
    </Modal>}
    {focusInvoiceEdit && <Modal display={!!focusInvoiceEdit} title="Facture" onClose={() => updateFocusInvoiceEdit(null)}>
      <InvoiceEdit invoiceId={focusInvoiceEdit?.id || ""} onDone={() => updateFocusInvoiceEdit(null)} />
    </Modal>}
    {focusContactEdit && <Modal display={!!focusContactEdit} title="Contact" onClose={() => updateFocusContactEdit(null)}>
      <ContactEdit id={focusContactEdit?.id || ""} onDone={() => updateFocusContactEdit(null)} />
    </Modal>}
    {focusOrderEdit && <Modal display={!!focusOrderEdit} title="Bon de commande" onClose={() => updateFocusOrderEdit(null)}>
      <OrderEdit id={focusOrderEdit?.id || ""} onDone={() => updateFocusOrderEdit(null)} />
    </Modal>}
    <h1>{customer?.name}</h1>

    <div className="row">

      <div className="sm-6">
        <div className="card">
          <div className="card-header bg-dark text-white">
            Contact
          </div>
          <div className="card-body">
            <table className="table table-stripped table-bordered">
              <tbody>
                {contactList?.map(contact => {
                  return <tr key={`contact${contact?.id}`} style={{ whiteSpace: "nowrap" }}>
                    <td>{contact?.firstname} {contact?.lastname} </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusContactEdit(contact)}>
                        <IconEdit />
                      </button>
                      <button className="btn btn-sm btn-danger ml-1">
                        <IconTrash />
                      </button>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card ">
          <div className="card-header bg-dark text-white">
            Bons de Commandes
          </div>
          <div className="card-body">
            <table className="table table-stripped table-bordered">
              <tbody>
                {orderList?.map(order => {
                  return <tr key={`order_${order?.id}`}>
                    <td>{order?.publicId}</td>
                    <td><OrderStatus status={order?.status as OrderStatusEnum} /></td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <button className="btn btn-sm btn-warning" onClick={() => updateFocusOrderEdit(order)}>
                        <IconEdit />
                      </button>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card ">
          <div className="card-header bg-dark text-white">
            Pref Paiement
          </div>
          <div className="card-body">
            {(customer && stripePk) && <PaymentPrefList customerId={customer?.id} stripePk={stripePk} />}
          </div>
        </div>
      </div>

      <div className="sm-6">
        <div className="card">
          <div className="card-header bg-dark text-white">
            Factures
          </div>
          <div className="card-body">
            <table className="table table-stripped table-bordered">
              <tbody>
                {[...invoiceList || []]
                  ?.sort((el1, el2) => el1.date > el2.date ? -1 : 1)
                  ?.map(invoice => {

                    const viewUrl = URL_INVOICE_PDF.replace("${apiRoot}", api_enpoint).replace("${publicId}", invoice?.publicId).replace("${accessToken}", findToken()).replace("${stamp}", `${new Date().getTime()}`)

                    return <tr key={`invoice${invoice?.id}`}>
                      <td>{invoice?.publicId}</td>
                      <td><InvoiceStatus status={invoice?.status as InvoiceStatusEnum} /></td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <button className="btn btn-sm btn-dark ml-1" onClick={() => updateFocusInvoiceView(invoice)}>
                          <IconSearch />
                        </button>
                        <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusInvoiceEdit(invoice)}>
                          <IconEdit />
                        </button>
                      </td>
                    </tr>
                  })}
              </tbody>
            </table>
            <Pagination cursor={invoiceQuery?.data?.list?.cursor} onChange={(page) => {
              invoiceQuery?.refetch({
                cursor: {
                  page,
                  limit: 10
                },
                input: {
                  contract: contractId ? [contractId] : [],
                  status: [
                    InvoiceStatusEnum.STATUS_DRAFT,
                    InvoiceStatusEnum.STATUS_ERROR,
                    InvoiceStatusEnum.STATUS_CANCELLED,
                    InvoiceStatusEnum.STATUS_PAID,
                    InvoiceStatusEnum.STATUS_PAYMENT_WAITING,
                    InvoiceStatusEnum.STATUS_PENDING,
                  ],
                }
              })
            }} />
          </div>
        </div>
      </div>
    </div>
    <div className="card mt-2">
      <div className="card-footer">
        <button className="btn btn-sm btn-dark" onClick={() => updateFocusEdit(true)}>Modifier</button>
      </div>
    </div>
  </div>

}