import { createContext } from "react";

export type ModalRouterComponent = {
  component: any,
  title: string,
  modalSize?: "xl" | "lg" | "sm"
}

export const modalRouterContext = createContext<{ componentStack: any[], pushComponent: (component: ModalRouterComponent) => void, closeModal: () => void, }>({
  componentStack: [],
  pushComponent: (component: ModalRouterComponent) => { },
  closeModal: () => { },
})
