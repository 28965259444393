import { PanelDisplayModule } from "components/backoffice/VIDEO/Panel/Module/PanelDisplayModule"
import { useParams } from "react-router-dom"

export const PanelDisplayRoute = () => {

  const params = useParams()
  const { id, panelId } = params
  const hasParams = !!id && !!panelId


  return <div>
    {hasParams && <PanelDisplayModule videoConfigId={id} panelId={panelId} />}
  </div>

}