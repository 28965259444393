import React, { useState, useEffect } from "react"
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { Link as ScrollLink, Element, scroller, animateScroll } from "react-scroll"

import { Vars } from "libs/Vars"

import { HeaderBar } from "components/front/Navigation/HeaderBar/HeaderBar"

import thumbnail from "static/images/thumb_rf.png"
import logo from 'static/images/logo.png';

import background from "static/images/background.jpg"

import docker from "static/images/tools/docker.png"
import apache from "static/images/tools/httpd.png"
import php from "static/images/tools/php.png"
import node from "static/images/tools/node.png"
import traefik from "static/images/tools/traefik.png"
import asterisk from "static/images/tools/asterisk.png"
import ansible from "static/images/tools/ansible.png"
import react from "static/images/tools/react.png"
import wifi from "static/images/tools/wifi.svg"
import linux from "static/images/tools/linux.png"

import bacto from "static/images/brands/bacto.png"
import coeurdedressing from "static/images/brands/coeurdedressing.png"
import devriendt from "static/images/brands/devriendt.png"
import grandeurNature from "static/images/brands/grandeur_nature.png"
import h8 from "static/images/brands/h8.png"
import lmu from "static/images/brands/la_maison_d_uzes.jpg"
import lvc from "static/images/brands/le_vieux_castillon.svg"
import pocklife from "static/images/brands/pocklife.png"
import scop_ecologic from "static/images/brands/scop_ecologic.png"
import lesCausantes from "static/images/brands/les_causantes.png"

import Card from "react-bootstrap/Card"
import CardDeck from "react-bootstrap/CardDeck"
import Carousel from "react-bootstrap/Carousel"

import "./style.scss"
import { URL_HOSTING, URL_DEV, URL_NETWORK, URL_PHONE, URL_ROOT, URL_CGU } from "static/constant/front/url";

export const MainRoute = (props: any) => {

  const [carouselIndex, updateCarouselIndex] = useState(0)

  const _handleCarouselSelect = (index: any) => {
    updateCarouselIndex(index)
  }

  const _selectCarouselIndex = (index: any) => {
    scroller.scrollTo('carousel', {
      duration: 500,
      // delay: 100,
      smooth: true,
      // containerId: 'ContainerElementID',
      offset: -150, // Scrolls to element + 50 pixels down the page
    })
    updateCarouselIndex(index)
  }

  useEffect(() => {
    const { match } = props
    const { path } = match || {}
    switch (path) {

      case `${URL_HOSTING}`: {
        _selectCarouselIndex(0)
        break;
      }
      case `${URL_PHONE}`: {
        _selectCarouselIndex(1)
        break;
      }
      case `${URL_NETWORK}`: {
        _selectCarouselIndex(2)
        break;
      }
      case `${URL_DEV}`: {
        _selectCarouselIndex(3)
        break;
      }
    }
  }, [])


  const _renderHelmet = () => {
    const { match } = props
    const { path } = match || {}
    switch (path) {
      default:
      case `${URL_HOSTING}`: {
        return <Helmet>
          <title>Hébergement</title>
          <link rel="icon" type="image/png" href="favicon.png"></link>
          <link rel="canonical" href={Vars["URL_FRONT_CANONICAL"]} />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=no"></meta>
          <meta name="description" content="Besoin d'un site ? d'une application ? d'une messagerie pro ? On vous simplifie la vie..."></meta>
        </Helmet>
      }
      case `${URL_DEV}`: {
        return <Helmet>
          <title>Développement</title>
          <link rel="canonical" href={Vars["URL_FRONT_CANONICAL"]} />
          <link rel="icon" type="image/png" href="favicon.png"></link>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=no"></meta>
          <meta name="description" content="Du simple script au réseau social, en passant par l'application métier, nous vous proposerons les solutions adpatées à votre besoin."></meta>
        </Helmet>
      }
      case `${URL_NETWORK}`: {
        return <Helmet>
          <title>Réseaux</title>
          <link rel="canonical" href={Vars["URL_FRONT_CANONICAL"]} />
          <link rel="icon" type="image/png" href="favicon.png"></link>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=no"></meta>
          <meta name="description" content="Besoin de Wifi d'entreprise ? Wifi public ? Connectivité 4G / fibre ? Comptez sur nous..."></meta>
        </Helmet>
      }
      case `${URL_PHONE}`: {
        return <Helmet>
          <title>Téléphonie</title>
          <link rel="canonical" href={Vars["URL_FRONT_CANONICAL"]} />
          <link rel="icon" type="image/png" href="favicon.png"></link>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=no"></meta>
          <meta name="description" content="En quelques clics, vous installez et configurez un standard professionnel qui répond à tous vos scénarios."></meta>
        </Helmet>
      }
      case `${URL_ROOT}`: {
        return <Helmet>
          <title>box4b</title>
          {/* <link href="https://fonts.googleapis.com/css?family=Lato&display=swap" rel="stylesheet" /> */}
          <link rel="canonical" href={Vars["URL_FRONT_CANONICAL"]} />
          <link rel="icon" type="image/png" href="favicon.png"></link>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=no"></meta>
          <meta name="description" content="Box4b vous accompagne pour tous vos besoins informatiques, téléphonie, développements et réseaux. Bienvenue."></meta>
        </Helmet>
      }
    }
  }


  return (
    <div className="main-route-container">
      {_renderHelmet()}
      <HeaderBar />
      <section id={`section-1`}>
        <div className="div-welcome" style={{ backgroundImage: `url(${background})` }}>
          <div>
            <h1>Bienvenue ;)</h1>
            <h2>Vous recherchez un partenaire de confiance pour vos projets et systèmes informatiques ?</h2>
            <ScrollLink to="section-2" offset={-60} duration={500} smooth={true}><button className="btn-lg">Découvrez nos services</button></ScrollLink>
          </div>
        </div>
      </section>
      <section id={`section-2`}>
        <div className="our-services even">
          <div className="container">
            <h2>nos services</h2>
            <h3>en quoi pouvons-nous vous aider ?</h3>
            <div>
              <CardDeck>
                <Card className="with-footer with-cursor" border={(carouselIndex === 0) ? "secondary" : ""} onClick={() => _selectCarouselIndex(0)}>
                  <Card.Title>Hébergement</Card.Title>
                  <Card.Body>
                    <ul>
                      <li>Sites & Applications Web</li>
                      <li>Mails & Suite bureautiques</li>
                      <li>Nom de Domaines</li>
                    </ul>
                  </Card.Body>
                  <Card.Footer><a onClick={() => _selectCarouselIndex(0)}><span className="icon-more icon" />{"Plus d'infos"}</a></Card.Footer>
                </Card>
                <Card className="with-footer with-cursor" border={(carouselIndex === 1) ? "secondary" : ""} onClick={() => _selectCarouselIndex(1)}>
                  <Card.Title>Téléphonie</Card.Title>
                  <Card.Body>
                    <ul>
                      <li>Standard Téléphonique</li>
                      <li>{"Centre d'appels"}</li>
                      <li>{"Intégration (CTI, etc)"}</li>
                    </ul>
                  </Card.Body>
                  <Card.Footer><a onClick={() => _selectCarouselIndex(1)}><span className="icon-more icon" />{"Plus d'infos"}</a></Card.Footer>
                </Card>
                <Card className="with-footer with-cursor" border={(carouselIndex === 2) ? "secondary" : ""} onClick={() => _selectCarouselIndex(2)}>
                  <Card.Title>Réseaux</Card.Title>
                  <Card.Body>
                    <ul>
                      <li>Solutions Wifi & Connectivité</li>
                      <li>Conception Infrastructure</li>
                      <li>Maintenance & Evolutions</li>
                    </ul>
                  </Card.Body>
                  <Card.Footer><a onClick={() => _selectCarouselIndex(2)}><span className="icon-more icon" />{"Plus d'infos"}</a></Card.Footer>
                </Card>
                <Card className="with-footer with-cursor" border={(carouselIndex === 3) ? "secondary" : ""} onClick={() => _selectCarouselIndex(3)}>
                  <Card.Title>développement</Card.Title>
                  <Card.Body>
                    <ul>
                      <li>Applications Mobile (iOS, Android)</li>
                      <li>Api & Interfaces</li>
                      <li>Applications Métiers</li>
                      <li>Intégration Progiciel & ERP</li>
                    </ul>
                  </Card.Body>
                  <Card.Footer><a onClick={() => _selectCarouselIndex(3)}><span className="icon-more icon" />{"Plus d'infos"}</a></Card.Footer>
                </Card>
              </CardDeck>
              <div className="card-focus">
                <section id="carousel">
                  <Element name="carousel">
                    <Carousel interval={null} activeIndex={carouselIndex} onSelect={_handleCarouselSelect} indicators={false} prevIcon={<span className="icon-carousel icon-chevron-left" />} nextIcon={<span className="icon-carousel icon-chevron-right" />}>
                      <Carousel.Item>
                        <div className="card-spacer">
                          <Card>
                            <div>
                              <Card.Header>Hébergement</Card.Header>
                              <Card.Body>
                                <div>
                                  <div className="pitch">
                                    <h3>Notre Expertise</h3>
                                    <div>
                                      <p>{"Besoin d'un site ? d'une application ? d'une messagerie pro ? On vous simplifie la vie..."}</p>
                                      <p>&nbsp;</p>
                                      <p>Nous vous accompagnons et nous chargeons de toutes les parties administratives et techniques.</p>
                                      <p>Vous avez accès à nos outils personnalisés en constante évolution, issus de notre recherche et de nos expériences.</p>
                                      <p>Des technologies que nous utilisons quotidiennement et qui ont fait leur preuves.</p>
                                    </div>
                                  </div>
                                  <div className="tools">
                                    <h3>Nos Outils</h3>
                                    <div>
                                      <img src={docker} alt=""></img>
                                      <img src={apache} alt=""></img>
                                      <img src={php} alt=""></img>
                                      <img src={node} alt=""></img>
                                      <img src={traefik} alt=""></img>
                                      <img src={linux} alt=""></img>
                                    </div>
                                  </div>
                                  <div className="refs">
                                    <h3>Ils nous font confiance</h3>
                                    <div>
                                      <img src={h8} alt=""></img>
                                      <img src={coeurdedressing} alt=""></img>
                                      <img src={pocklife} alt=""></img>
                                      <ScrollLink to="section-3" offset={-60} duration={500} smooth={true}><span className="icon-ellipsis " /></ScrollLink>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </div>
                          </Card>
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="card-spacer">
                          <Card>
                            <div>
                              <Card.Header>Téléphonie</Card.Header>
                              <Card.Body>
                                <div>
                                  <div className="pitch">
                                    <h3>Notre Expertise</h3>
                                    <div>
                                      <p>{"Nous vous simplifions la téléphonie d'entreprise."}</p>
                                      <p>&nbsp;</p>
                                      <p>En quelques clics, vous avez accès à une interface simple et efficace pour gérer votre standard et vos différents scénarios.</p>
                                      <p>Et évidemment, nous sommes présent pour vous accompagner et identifier les meilleurs leviers pour votre business.</p>
                                    </div>
                                  </div>
                                  <div className="tools">
                                    <h3>Nos Outils</h3>
                                    <div>
                                      <img src={asterisk} alt=""></img>
                                      <img src={node} alt=""></img>
                                    </div>
                                  </div>
                                  <div className="refs">
                                    <h3>Ils nous font confiance</h3>
                                    <div>
                                      <img style={{ maxWidth: 200 }} src={grandeurNature} alt=""></img>
                                      <img src={bacto} alt=""></img>
                                      <ScrollLink to="section-3" offset={-60} duration={500} smooth={true}><span className="icon-ellipsis " /></ScrollLink>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </div>
                          </Card>
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="card-spacer">
                          <Card>
                            <div>
                              <Card.Header>Réseaux</Card.Header>
                              <Card.Body>
                                <div>
                                  <div className="pitch">
                                    <h3>Notre Expertise</h3>
                                    <div>
                                      <p>{"Nous concevons, nous installons et assurons la maintenance de tous systèmes réseaux. Quel que que soit votre secteur d'activité."}</p>
                                      <p>&nbsp;</p>
                                      <p>{"A l'ère du cloud, une connectivité de qualité est plus que vitale pour tous les business"}</p>
                                      <p>{"Besoin de Wifi d'entreprise ? Wifi public ? Connectivité 4G / fibre ? Comptez sur nous..."}</p>
                                    </div>
                                  </div>
                                  <div className="tools">
                                    <h3>Nos Outils</h3>
                                    <div>
                                      <img src={wifi} alt=""></img>
                                      <img src={ansible} alt=""></img>
                                      <img src={linux} alt=""></img>
                                    </div>
                                  </div>
                                  <div className="refs">
                                    <h3>Ils nous font confiance</h3>
                                    <div>
                                      <img src={h8} alt=""></img>
                                      <img src={lvc} alt=""></img>
                                      <img src={scop_ecologic} alt=""></img>
                                      <img src={devriendt} alt=""></img>
                                      <img src={lmu} alt=""></img>
                                      <ScrollLink to="section-3" offset={-60} duration={500} smooth={true}><span className="icon-ellipsis " /></ScrollLink>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </div>
                          </Card>
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="card-spacer">
                          <Card>
                            <div>
                              <Card.Header>Développement</Card.Header>
                              <Card.Body>
                                <div>
                                  <div className="pitch">
                                    <h3>Notre Expertise</h3>
                                    <div>
                                      <p>Nous travaillons avec une équipe de développeurs et consultants seniors, passionnés et guidés par le sens du service.</p>
                                      <p>&nbsp;</p>
                                      <p>{"Du simple script au réseau social, en passant par l'application métier, nous vous proposerons les solutions adaptées à votre besoin."}</p>
                                    </div>
                                    <h3>Notre Méthode</h3>
                                    <div>
                                      <p>{"Proximité & Agilité"}</p>
                                    </div>
                                  </div>
                                  <div className="tools">
                                    <h3>Nos Outils</h3>
                                    <div className="logos">
                                      <img src={react} alt=""></img>
                                      <img src={php} alt=""></img>
                                      <img src={node} alt=""></img>
                                      <span className="icon-apple"></span>
                                      <span className="icon-android"></span>
                                    </div>
                                  </div>
                                  <div className="refs">
                                    <h3>Ils nous font confiance</h3>
                                    <div>
                                      <img src={lesCausantes} alt=""></img>
                                      <img src={pocklife} alt=""></img>
                                      <img src={coeurdedressing} alt=""></img>
                                      <ScrollLink to="section-3" offset={-60} duration={500} smooth={true}><span className="icon-ellipsis " /></ScrollLink>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </div>
                          </Card>
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </Element>
                </section>
              </div>
            </div>
            <div className="call-for-action">
              <ScrollLink to="section-3" offset={-60} duration={500} smooth={true}><button className="btn-lg">Nous contacter</button></ScrollLink>
            </div>
          </div>
        </div>
        {/* <div>
            {this._renderAccordeon()}
          </div> */}
      </section>
      {/* <section id={`section-3`}>
          <div className="our-services even ">
            <div className="container">
              <h2>qui sommes nous</h2>
              <h3>et comment nous travaillons</h3>
            </div>
          </div>
        </section> */}
      {/* <hr /> */}
      <section id={`section-3`}>
        <div className="our-services">
          <div className="container center">
            <h2>contact</h2>
            <h3>on en parle ?</h3>
            <div className="contact-container">
              <Card className="">
                <Card.Body>
                  {/* <Card.Text> */}
                  <div className="row">
                    <div className="col-6">
                      <div className="contact rounded-circle">
                        <img src={thumbnail} className="" alt=""></img>
                      </div>
                    </div>
                    <div className="col-6 details">
                      <span className="span-row">Romain</span>
                      <span className="span-row"><a href="https://www.linkedin.com/in/romain-fenninger-7459b738" target="_blank" rel="noopener noreferrer" >
                        Linkedin</a></span>
                      <span className="span-row">
                        romain@box4b.fr</span>
                      <PhoneNumberHide />
                    </div>
                  </div>
                  {/* </Card.Text> */}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer pt-4 my-md-5 pt-md-5 border-top">
        <div className="container">
          <div className="row">
            <img className="logo-img" src={logo} alt="" />
            <span className="logo-text">box4b</span>
            <span className="vcard">SIRET 81750079600039<br />
              <Link to={URL_CGU}>Conditions générales d'utilisation</Link>
            </span>
          </div>
        </div>
      </footer>
    </div >
  )
}

export const PhoneNumberHide = () => {

  const phoneNumberItemList = ["+33", "(0)1", "88", "33", "64", "82"]

  const [isHidden, setHide] = useState(true)
  return isHidden ? <span><button className="btn-sm" onClick={setHide.bind(this, false)}>Afficher le numéro</button></span> : <span><span className="icon icon-phone" />{phoneNumberItemList.join('.')}</span>

}
