import { useQuery } from "@apollo/client"
import { IconAddressCard, IconEdit, IconMore, IconSearch, Modal } from "@zipou/front_tools"
import { VideoConfig } from "model"
import { useState } from "react"
import { VideoConfigEdit } from "../Edit/VideoConfigEdit"
import videoConfigListGql from "graphql/VIDEO/Config/query/videoConfigList.gql"
import { VideoConfigAdd } from "../Add/VideoConfigAdd"
import { URL_CAMERA_LIST, URL_PANEL_LIST } from "static/constant/backoffice/url"
import { Link } from "react-router-dom"
import { VideoConfigModuleDisplay } from "../Module/VideoConfigModuleDisplay"


export const VideoConfigList = () => {

  const { data, refetch } = useQuery<{ list: VideoConfig[] }>(videoConfigListGql)

  const [focusEdit, updateFocusEdit] = useState<VideoConfig | null>(null)
  const [focusAdd, updateFocusAdd] = useState<boolean>(false)

  const videoConfigList = data?.list

  const hasData = videoConfigList && videoConfigList?.length > 0

  return <div>
    {!!focusAdd && <Modal display title="Ajouter une config" onClose={() => updateFocusAdd(false)}>
      <VideoConfigAdd onDone={() => {
        updateFocusAdd(false)
        refetch()
      }} />
    </Modal>}
    {!!focusEdit && <Modal display title="Editer une config" onClose={() => updateFocusEdit(null)}>
      <VideoConfigModuleDisplay id={focusEdit?.id} token={focusEdit?.token || ""} />
    </Modal>}
    <h1>Liste des configurations Video</h1>
    <table className="table table-striped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <td className="bg-dark text-white">Nom</td>
          <td className="bg-dark text-white">Creds</td>
          <td className="bg-dark text-white">Actions</td>
        </tr>
      </thead>
      <tbody>
        {!hasData && <tr>
          <td colSpan={3}>
            <div className="alert alert-info">Rien à afficher</div>
          </td>
        </tr>}
        {videoConfigList?.map(videoConfig => <tr key={`videoConfig_${videoConfig?.id}`}>
          <td>{videoConfig?.name || videoConfig?.id}</td>
          <td>
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <th>Id</th>
                  <td>{videoConfig?.id}</td>
                </tr>
                <tr>
                  <th>Token</th>
                  <td>{videoConfig?.token}</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>

            <Link to={`/manager` + URL_CAMERA_LIST.replace(':id', videoConfig?.id).replace(':token', videoConfig?.token || "")}>
              <button className="btn btn-sm btn-primary ml-1">
                Caméras
              </button>
            </Link>

            <Link to={`/manager` + URL_PANEL_LIST.replace(':id', videoConfig?.id).replace(':token', videoConfig?.token || "")}>
              <button className="btn btn-sm btn-secondary ml-1">
                Panels
              </button>
            </Link>

            <button className="btn btn-sm btn-warning ml-1" onClick={() => updateFocusEdit(videoConfig)}>
              <IconEdit />
            </button>
          </td>
        </tr>)}
      </tbody>
    </table>
    <div>
      <button className="btn btn-dark btn-sm ml-1" onClick={() => updateFocusAdd(true)}>Ajouter</button>
    </div>

  </div >
}