import { PanelViewModule } from "@zipou/video_front"

type PanelDisplayModuleProps = {
  videoConfigId: string,
  panelId: string,
}

export const PanelDisplayModule = ({ videoConfigId, panelId }: PanelDisplayModuleProps) => {

  const env = process.env.NODE_ENV

  const moduleConfig = env === "development" ? {
    url: "http://localhost:4001",
    wsUrl: "ws://localhost:4001",
  } : {
    url: "https://www.box4b.fr",
    wsUrl: "wss://www.box4b.fr/subscriptions",
  }


  return <div>
    <PanelViewModule
      configId={videoConfigId}
      panelId={panelId}
      moduleConfig={moduleConfig}
    />
  </div>

}