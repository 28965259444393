import { PanelListModuleDisplay } from "components/backoffice/VIDEO/Panel/Module/PanelListModule"
import { Breadcrumb as BootstrapBreadcrumb } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { URL_PANEL_DISPLAY, URL_VIDEO_CONFIG_LIST } from "static/constant/backoffice/url"


export const PanelListModuleDisplayRoute = () => {

  const params = useParams()
  const { id, token } = params
  const hasParams = !!id && !!token
  
  return <div className="video-panel-list-route">


    <BootstrapBreadcrumb>
      <BootstrapBreadcrumb.Item active>Video</BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item >
        <Link to={"/manager" + URL_VIDEO_CONFIG_LIST}>
          Configurations
        </Link>
      </BootstrapBreadcrumb.Item>
      <BootstrapBreadcrumb.Item active>Panel</BootstrapBreadcrumb.Item>
    </BootstrapBreadcrumb>


    {hasParams && <PanelListModuleDisplay videoConfigId={id || ""} token={token || ""} />}
  </div>
}